.checkout {
  &.viewcart &-panel--viewcart {
    .cart-items {
      &__header {
        &--products {
          width: 42.5%;
        }
        &--price {
          width: 19%;
        }
        &--qty {
          width: 24%;
        }
        &--total {
          width: 13%;
        }
      }
      &__item {
        &--thumb {
          width: 19%;
        }
        &--desc {
          width: 32%;
        }
        &--price {
          width: 19%;
        }
        &--qty {
          width: 20.5%;
        }
        &--total {
          width: 17%;
        }
      }
      .replenishment {
        display: none;
      }
      &__item--thumb {
        width: 10%;
      }
    }
  }
  .direct-debit {
    &-options {
      select {
        width: 225px;
      }
    }
    &-nickname {
      input {
        width: 30%;
        margin-top: 5px;
      }
    }
  }
  &__sidebar {
    .shopping-viewcart-panel {
      border-bottom: 1px solid $dark-gray;
      padding: 0px 0px 20px 0px;
    }
  }
  &.shipping {
    .checkout-panel--shipping {
      .shipping-address-display-content {
        .change-address {
          float: right;
        }
      }
    }
  }
  &.viewcart,
  &.shipping {
    .checkout__sidebar {
      padding: 0;
      .checkout-panel {
        padding: 0 15px;
        &--offer-code {
          padding: 0 15px;
        }
        &__header {
          .checkout-panel__heading {
            border-bottom: 1px solid $dark-gray;
          }
          .offer-code-content {
            display: block;
            #offer_code {
              .offer-code-content__input {
                width: 55%;
                float: left;
                input[type='text'] {
                  width: 100%;
                }
              }
              .offer-code-content__submit {
                margin: 0 10px;
                float: left;
              }
            }
          }
        }
      }
      .product-list {
        text-align: center;
        li {
          margin-bottom: 15px;
        }
        .product-name,
        .product-brief__title {
          font-family: $font--heading-main;
          letter-spacing: 0;
          font-weight: normal;
          margin: 5px 0;
          font-size: 18px;
          a {
            color: $color-black;
            text-decoration: none;
          }
        }
        .price {
          font-size: 13px;
          letter-spacing: -0.01em;
          line-height: 30px;
          font-weight: normal;
          float: right;
          display: inline-block;
        }
      }
      .tab-switcher {
        .checkout-panel {
          margin: 15px;
          padding: 0;
        }
        .continue-shopping__container--outer {
          display: none;
        }
      }
    }
    .bottom-section-content {
      .order-summary-content {
        &__label {
          width: 70%;
          text-align: right;
        }
        &__value {
          padding-left: 65px;
        }
      }
    }
    .checkout-panel {
      &--recommended-products {
        float: left;
        width: 100%;
        border-top: 1px solid $dark-gray;
        .product-brief__actions {
          input[type='submit'] {
            white-space: initial;
            line-height: inherit;
          }
        }
      }
      &--viewcart-buttons,
      &--bottom-section {
        margin: 0;
        padding: 0;
        background: $color-white;
      }
      .total-container {
        &__content {
          float: left;
          width: 100%;
          text-align: right;
          .label {
            width: 70%;
            margin: 0;
          }
          .value {
            width: 15%;
          }
        }
      }
      #order-summary-panel {
        float: left;
        width: 100%;
      }
      &--order-summary {
        float: left;
        width: 100%;
        padding: 0px 20px;
      }
    }
    .checkout__panel {
      border: 0px;
      &--header {
        border-bottom: 1px solid $dark-gray;
      }
    }
  }
  &__header {
    .checkout__heading {
      padding: 1em 0;
      border-bottom: 1px solid $dark-gray;
      text-align: left;
    }
  }
  .tab-bar {
    margin-left: 0;
    border: 0;
    margin-bottom: 0px;
    padding-bottom: 0px;
    display: block;
    overflow: hidden;
    li {
      margin-right: 0;
      float: left;
      display: inline-block;
      &.tab-header {
        &-samples {
          width: 50%;
        }
        &-favourties {
          width: 50%;
        }
      }
      a {
        @include font-smoothing(antialiased);
        @include appearance(none);
        background-color: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
        display: block;
        font-family: $font--heading-main;
        font-size: 18px;
        font-weight: 100;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 2px;
        padding: 0.75em 0;
        &.active {
          border: 1px solid $color-black;
          background-color: $color-black;
          color: $color-white;
          font-weight: 100;
        }
      }
      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
  .tab-content {
    clear: both;
    .continue-shopping-outer-container {
      display: none;
    }
    #favorites-pane,
    #past-purchases-pane {
      .product-list {
        li.product {
          .details {
            width: 50%;
            float: left;
            margin-left: 10px;
          }
        }
      }
    }
    #samples-pane {
      .product-list {
        li.product {
          .product-img {
            max-width: 30%;
            img {
              width: auto;
            }
          }
        }
      }
    }
  }
  .tab-pane {
    opacity: 0;
    height: 0;
    overflow: hidden;
    -webkit-transition: opacity 1s ease;
    -moz-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    -ms-transition: opacity 1s ease;
    transition: opacity 1s ease;
    header {
      display: none;
    }
  }
  .tab-pane.active {
    display: block;
    opacity: 1;
    height: auto;
  }
  .tab-switcher {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 15px;
    .product-list li.product {
      width: 100%;
      min-height: 0;
      .product-img {
        text-align: center;
        margin: auto;
        width: 100%;
        max-width: 80px;
        float: left;
        img {
          width: auto;
          height: 85px;
        }
        a.view-larger {
          display: none;
        }
      }
      .shades-list {
        display: none;
      }
      .add-to-cart {
        float: left;
        width: 100%;
        margin: 10px 0;
        input[type='submit'] {
          width: 100%;
        }
      }
      .details {
        margin-left: 0px;
        float: right;
        text-align: left;
        width: 70%;
      }
      ul.skus {
        float: left;
        width: 100%;
        li {
          margin: 10px 0;
          padding: 0;
          width: 100%;
          border: 0;
          .form-item {
            margin-bottom: 0px;
            a.sample-select-button {
              width: 100%;
              cursor: pointer;
              border: 1px solid $color-black;
              background: $color-white;
              color: $color-black;
              &:hover {
                border: 1px solid $color-black;
                background: $color-white;
                color: $color-black;
              }
              &.selected {
                background: $color-black;
                color: $color-white;
              }
            }
          }
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .checkout__panel--heading {
      padding-left: 0px;
    }
    .tab-bar {
      li {
        display: inline-block;
      }
    }
  }
  .select-box {
    &__label,
    & {
      min-width: auto;
      width: 100%;
      max-width: 100%;
      &:after {
        background-color: $white;
      }
    }
  }
  .checkout__content {
    @include breakpoint($landscape-up) {
      float: left;
      padding: 0 30px 0 0;
      width: 66.6666667%;
    }
    &.hasPaypal {
      margin: 0;
    }
  }
  .checkout__sidebar {
    @include breakpoint($landscape-up) {
      width: 33.33333%;
    }
  }
  &--confirmation-page {
    .registration-content__create-account {
      display: block;
    }
    .checkout__content {
      width: 100%;
    }
    .checkout-panel--confirmation {
      div.print-buttons {
        margin-top: 10px;
      }
    }
  }
  .payment-edit {
    &-content {
      .payment-type {
        label {
          .label-content {
            display: none;
          }
        }
      }
    }
  }
  .section-email-promotions {
    float: none;
    width: auto;
  }
  &.viewcart {
    .total-container {
      .total {
        &.value {
          @include breakpoint($landscape-up) {
            width: 30%;
            padding-left: 60px;
            text-align: left;
          }
        }
        &.label {
          @include breakpoint($landscape-up) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .giftmessage {
    .form-item {
      width: 48%;
      &.card-message {
        display: block;
        width: 98.5%;
        textarea {
          width: 100%;
        }
      }
    }
  }
  .viewcart-paypal-button {
    float: #{$ldirection};
    .terms-conditions {
      margin-#{$ldirection}: 55%;
      input {
        position: inherit;
      }
    }
    .paypal-button {
      float: #{$ldirection};
      margin-#{$ldirection}: 20%;
      position: absolute;
    }
    input {
      opacity: inherit;
      margin-top: 4px;
      float: #{$ldirection};
    }
    .terms-conditions-txt {
      margin-#{$ldirection}: 16px;
    }
  }
  .checkout-header {
    &.hasPaypal {
      padding-bottom: 92px;
    }
    #top-viewcart-buttons {
      .continue-buttons {
        padding-#{$rdirection}: 13%;
        .btn {
          width: 335px;
        }
      }
    }
  }
}

.payment_frame {
  iframe {
    width: 100%;
    height: 400px;
  }
}

#checkout {
  &_complete {
    .field-container {
      &--grid {
        margin-bottom: 15px;
      }
    }
  }
  &_billing_panel {
    .view-address {
      .change-address {
        float: right;
      }
    }
  }
}

.checkout-single-page {
  .checkout-panel {
    &--shipping {
      .checkout-panel__content {
        .select-address {
          .form-item {
            &.select-menu {
              margin: 20px 0;
            }
          }
        }
      }
    }
  }
}

.page-wrapper {
  .checkout-single-page {
    .checkout-panel {
      &--shipping,
      &--payment {
        .checkout-panel {
          &__content {
            &.select-address {
              .address-form {
                .form-item {
                  &.title {
                    width: 49%;
                    .select-box {
                      &,
                      &__label {
                        width: 100%;
                      }
                    }
                  }
                  &.first-name {
                    width: 49%;
                    float: left;
                  }
                  &.last-name {
                    width: 49%;
                    float: right;
                  }
                  &.address1,
                  &.address2 {
                    width: 100%;
                  }
                  &.postal-code {
                    width: 23.5%;
                    float: left;
                    margin-right: 0;
                  }
                  &.city {
                    width: 23.5%;
                    margin-right: 2%;
                    margin-left: 2%;
                    float: left;
                  }
                  &.state {
                    width: 49%;
                    margin-right: 0;
                    float: left;
                    label {
                      display: none;
                    }
                    .select-box {
                      &,
                      &__label {
                        width: 100%;
                      }
                      .select-box__options {
                        &.open {
                          max-height: 15em;
                        }
                      }
                    }
                  }
                  &.phone-1,
                  &.delivery-instruction {
                    width: 49%;
                    float: left;
                    margin-right: 0;
                  }
                  &.phone-2 {
                    width: 49%;
                    float: left;
                    margin-right: 0;
                    margin-left: 0;
                    label:after {
                      content: '';
                    }
                  }
                  &.delivery-instruction {
                    textarea {
                      width: 100%;
                    }
                  }
                  &.country-id {
                    margin: 10px 0;
                    width: 100%;
                  }
                  &.select {
                    label {
                      display: block;
                    }
                  }
                }
              }
            }
            .address-form {
              .title {
                label {
                  display: block;
                }
              }
              .company-name {
                width: 100%;
              }
              .select-box {
                &__label {
                  width: 348px;
                  margin-bottom: 10px;
                }
              }
            }
            .continue-button {
              &-wrapper {
                margin-top: 10px;
              }
            }
            .address-to-use {
              .ship_group_1 {
                width: 300px;
                float: left;
                input[type='radio'] {
                  width: 5%;
                }
              }
            }
          }
        }
      }
      &--review {
        .messages {
          display: block;
        }
      }
      &--payment {
        .checkout-panel__content {
          padding: 0 20px 20px;
          .address {
            .checkout-panel__heading {
              padding: 0;
            }
          }
          .installopts {
            .select-box__options {
              max-height: 100px;
            }
          }
        }
      }
    }
    .checkout {
      &__sidebar {
        .checkout-panel {
          &--links {
            display: block;
          }
          &--offer-code {
            border-bottom: none;
          }
        }
      }
    }
  }
}
