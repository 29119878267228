.js-signup-popup-overlay-cbox {
  #cboxLoadedContent {
    padding: 0;
    overflow: hidden;
  }
  #cboxClose:before {
    font-size: 23px;
  }
}

.signup-popup-container {
  width: 450px;
  height: 500px;
  padding: 40px 35px;
  text-align: center;
  .signup-popup {
    &_text {
      .title-text {
        h2 {
          font-size: 35px;
          font-weight: bold;
          line-height: 1;
          margin: 0;
          padding: 15px 0;
        }
      }
    }
    &__heading {
      margin: 0;
      padding-top: 50px;
      p {
        line-height: 1em;
      }
    }
    .tooltip {
      &:hover {
        .tooltiptext-over {
          max-width: unset;
        }
      }
      .tooltiptext-over::before {
        left: 50%;
      }
    }
  }
  .email-teaser-text {
    margin: 0 auto;
    font-size: 14px;
  }
  input {
    &[type='text'],
    &[type='email'],
    &[type='url'],
    &[type='password'],
    &[type='tel'],
    &[type='number'],
    &[type='search'],
    &[type='date'],
    &[type='time'] {
      width: 350px;
    }
  }
  select {
    width: 350px;
  }
  .form-item {
    &.name-field {
      margin: 0 0 20px 0;
    }
  }
  .signin-block {
    &__checkboxes {
      width: 400px;
      margin: 0 auto;
      text-align: justify;
    }
    &__checkbox {
      float: left;
    }
  }
  .dob-label {
    padding: 20px 0 10px 0;
  }
  .birth_day_container,
  .birth_month_container,
  .birth_year_container {
    select {
      width: 100px;
    }
  }
  .date_of_birth_section {
    max-width: 350px;
    margin: 0 auto;
    h2 {
      font-size: 20px;
    }
  }
  .date_of_birth_select {
    width: 100px;
  }
  .date_of_birth_content {
    display: flex;
    justify-content: space-evenly;
  }
  .select-box {
    &__label {
      min-width: 100px;
      padding: 0 0 0 1em;
      height: 30px;
      line-height: 2.5em;
    }
    &__label:after {
      line-height: 2.3em;
    }
    &__options {
      &.open {
        max-height: 90px;
      }
    }
    &__option {
      line-height: 2em;
    }
  }
  .clearer {
    clear: both;
  }
  .email_signup_tickbox {
    &_label {
      display: block;
      margin: 0 0 10px 0;
      position: relative;
      padding-left: 23px;
      float: left;
    }
    &_input {
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
  .signup-name-error,
  .errors {
    color: $red;
    display: none;
  }
  .progress,
  .success {
    margin: 20px 0 0 0;
  }
  .tooltip {
    margin-bottom: 1em;
  }
}

@include breakpoint(max-width 480px) {
  .js-signup-popup-overlay-cbox {
    #cboxLoadedContent {
      background: transparent;
      display: flex;
      align-items: center;
    }
  }
  .signup-popup-container {
    width: 100%;
    height: 520px;
    margin: 0 auto;
    input[type='text'],
    input[type='email'],
    input[type='url'],
    input[type='password'],
    input[type='tel'],
    input[type='number'],
    input[type='search'],
    input[type='date'],
    input[type='time'],
    select {
      width: 100%;
      height: 30px;
    }
    .form-item {
      &.name-field {
        margin: 0 0 10px 0;
      }
    }
    .date_of_birth {
      &_content {
        width: auto;
      }
      &_select {
        width: 93%;
        .birth_day_container {
          select {
            padding-left: 27px;
          }
        }
        &.clear {
          &.birth_month_container {
            select {
              padding-left: 21px;
            }
          }
          &.birth_year_container {
            select {
              padding-left: 25px;
            }
          }
        }
      }
      &_section {
        max-width: unset;
        h2 {
          margin-bottom: 0;
        }
        select {
          padding: 0;
          line-height: 1;
          font-size: 15px;
        }
      }
    }
    input {
      &.adpl--processed + label {
        font-size: 0.8em;
      }
    }
    .email-teaser-text {
      font-size: 13px;
    }
    .title-text {
      h2 {
        margin-bottom: 10px;
      }
    }
    .email_signup_tickbox_label {
      padding-left: 0;
    }
    .signin-block__checkboxes {
      width: auto;
    }
  }
}
