.sign-in-component {
  &__loyalty-program {
    display: none;
  }
}

.content {
  .gnav-util {
    &__content-inner {
      .signin-block {
        padding: 0 20px 20px 0;
        &__field--first-name,
        &__field--last-name,
        &__authed__loyalty {
          display: none;
        }
      }
    }
  }
}
