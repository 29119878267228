.page-wrapper {
  .pc-email-promotion {
    .label {
      display: inline-flex;
      padding-bottom: 10px;
    }
  }
  .social-login {
    clear: both;
  }
  .accepted-privacy-policy {
    .label {
      display: inline-flex;
      padding-bottom: 10px;
    }
  }
}

.waitlist-form {
  &__errors {
    li {
      color: $color-red;
    }
  }
}
