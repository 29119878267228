.ff-quiz__slide--3 {
  .ff-quiz__slide-image {
    &--desktop {
      @include breakpoint($portrait-up) {
        top: 0;
      }
    }
  }
  .ff-quiz__footer {
    @include breakpoint($portrait-up) {
      top: 265px;
    }
  }
}
