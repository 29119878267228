@mixin opc_pickpoint {
  @include breakpoint($small-down) {
    #pickpoint_customer_info {
      .local-collection {
        .show-collection-point {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .set-collection-point {
        .local-collection-button {
          width: 100%;
        }
      }
    }
  }
  .click_and_collect_map {
    .close-container {
      margin: 5px 0;
      float: right;
      .close-link {
        display: none;
      }
    }
    .overlay-content {
      height: 100%;
      width: 100%;
      position: relative;
      .overlay-background {
        background-color: $color-black;
        filter: alpha(opacity=50);
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        z-index: 200;
      }
      .button {
        background: none;
        border: none;
        &:hover {
          background: none;
        }
      }
      .map-container {
        overflow: visible;
        .search-box-cancel {
          position: absolute;
          top: 0;
          right: 0;
          margin: 5px 10px;
          width: auto;
          height: auto;
          cursor: pointer;
          background-color: transparent;
          color: $color-black;
          font-weight: lighter;
          padding: 0;
          &:after {
            content: '\274c';
          }
        }
        .address-map-location-panel {
          left: 0 !important;
          height: 100%;
          top: 0;
          width: 30%;
          padding-right: 0;
          display: block;
          position: absolute;
          margin: 20px 0 0 10px;
          overflow: hidden;
          bottom: 0;
          z-index: 2;
          @include breakpoint($small-down) {
            top: 9999px;
            width: 99%;
            margin: 1px;
            height: auto;
            &.active {
              top: 259px;
            }
          }
          label {
            display: inline-block;
          }
          &:hover {
            left: 0 !important;
          }
          .close {
            display: none !important;
          }
          header,
          .messages {
            display: none;
          }
          .locations {
            background-color: $color-white;
            height: 95%;
            overflow-y: scroll;
            cursor: pointer;
          }
          .location {
            border-color: $dark-gray;
            margin: 0;
            padding-top: 1em;
            &:first-child {
              padding-top: 20px !important;
              &:hover {
                padding: 0 0.5em 1em;
              }
            }
            &:hover {
              background: $light-gray;
            }
            .selected {
              border: 1px outset $dark-gray;
              padding: 1em 0.5em;
            }
            input {
              position: relative;
              float: left;
            }
            .service_provider,
            .title,
            .address,
            .location_delivery_cost {
              margin-left: 20px;
            }
            .button {
              .select-access-point {
                background-color: $color-white;
                border: 1px solid $dark-gray;
                color: $color-black;
                cursor: pointer;
                height: auto;
                margin: 10px 0 0;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none !important;
                width: 140px;
                &.disabled {
                  border: 1px solid $light-gray;
                  color: $dark-gray;
                  cursor: default;
                }
              }
            }
          } // location
        }
        .address-map-directions-panel,
        .address-map-location-panel {
          .location {
            border-bottom-color: $dark-gray;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            color: black;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            overflow-x: hidden;
            overflow-y: hidden;
            padding-bottom: 1em;
            padding-left: 0.5em;
            padding-right: 0.5em;
            padding-top: 0;
            transition-delay: 0s, 0s;
            transition-duration: 0.5s, 0.5s;
            transition-property: padding, background;
            transition-timing-function: ease, ease;
          }
        }
        .map-wrapper {
          /* height: 80%;
            width: 70%;
            float: right;
            position: relative; */
          height: 630px;
          width: 100%;
          vertical-align: top;
          display: inline-block;
          @include breakpoint($small-down) {
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;
          }
          .address-map-options-search-panel {
            position: relative;
            top: 0;
            left: 38%;
            margin: 35px auto 10px;
            @include breakpoint($small-down) {
              left: 0;
            }
            .options-search-box-wrapper {
              .options-search-box {
                select {
                  background-color: $color-white;
                  border: 1px solid $dark-gray;
                  height: auto;
                  padding: 10px;
                  text-align: center;
                  width: 215px;
                  line-height: initial;
                  @include breakpoint($small-down) {
                    width: 100%;
                  }
                }
              }
            }
            .address-map-search-panel {
              float: none;
              position: initial;
              margin: 0 0 0 10px;
              display: inline-block;
              vertical-align: bottom;
              @include breakpoint($small-down) {
                position: relative;
                z-index: 1;
                margin: 0;
              }
              select {
                background-color: $color-white;
                border: 1px solid $dark-gray;
                height: 29px;
                margin: 0 10px;
                text-align: center;
                width: 200px;
                vertical-align: bottom;
              }
              .change-date-container {
                margin-top: 8px;
              }
              @include breakpoint($small-down) {
                display: block;
              }
            }
            @include breakpoint($small-down) {
              margin: 35px 20px 10px 20px;
              select {
                height: auto;
                margin: 0 0 20px 0;
                width: 100%;
                padding: 5px;
              }
            }
          } // end address-map-options-search-panel

          .maps {
            /* height: 400px;
              width: 670px; */
            width: 65%;
            height: 460px;
            margin: 25px 25px 30px 12px;
            position: relative;
            left: 31%;
            @include breakpoint($small-down) {
              height: 100%;
              width: 100%;
              position: relative;
              left: 0%;
              margin: 0;
            }
            .maps-toggle-container {
              position: absolute;
              top: 58px;
              right: 0;
              z-index: 2;
              /* display:inline-block !important; */
              span {
                line-height: 30px;
                padding: 8px 10px;
                border-radius: 0 0 5px 5px;
                color: $color-white;
                background-color: $color-black;
                border: 1px solid $color-black;
                margin: 0 1px;
                &:hover,
                selected {
                  border: 1px solid $color-black;
                  color: $color-black;
                  background-color: $color-white;
                }
              }
              @include breakpoint($small-down) {
                position: relative;
                text-align: right;
                margin: 0;
              }
            }
            .metro-map {
              .popup_container {
                position: absolute;
                padding: 14px 14px 9px;
                background-color: $color-white;
                border: 1px solid $dark-gray;
                border-radius: 6px;
                min-width: 100px;
                min-height: 100px;
                z-index: 101;
                top: 50px;
                .location {
                  .statuspoint {
                    border-radius: 5px;
                    border: 1px solid $color-black;
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    right: 0px;
                    top: 28px;
                  }
                }
                .close {
                  position: absolute;
                  right: 5px;
                  top: 5px;
                  text-decoration: none;
                  font-size: 14px;
                  font-weight: bold;
                  color: $dark-gray;
                  z-index: 102;
                  span {
                    &:before {
                      content: 'X';
                    }
                  }
                } // close
                .atop {
                  position: absolute;
                  top: -12px;
                  left: 50%;
                  margin-left: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 12px 12px 12px;
                  border-color: transparent transparent $dark-gray transparent;
                  &:after {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 11px 11px 11px;
                    border-color: transparent transparent $color-white transparent;
                    content: '';
                    top: 1px;
                    left: -11px;
                  }
                } //atop
                .abot {
                  position: absolute;
                  bottom: -12px;
                  left: 50%;
                  margin-left: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12px 12px 0 12px;
                  border-color: $dark-gray transparent transparent transparent;
                  &:after {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 11px 11px 0 11px;
                    border-color: $color-white transparent transparent transparent;
                    content: '';
                    top: -12px;
                    left: -11px;
                  }
                } //abot
                .itemspopuplist_one {
                  width: 220px;
                  height: 145px;
                }
                .itemspopuplist_two {
                  width: 450px;
                  height: 145px;
                }
                .andmore {
                  height: 220px;
                }
                .location_wrapper {
                  position: relative;
                  display: inline-block;
                  width: 220px;
                  height: 145px;
                  overflow: hidden;
                  text-align: initial;
                }
                .mCustomScrollBox {
                  position: absolute;
                  overflow: hidden;
                  height: 87%;
                  max-width: 100%;
                  outline: none;
                  direction: ltr;
                  left: 0px;
                  overflow: auto;
                  padding: 0px 0px 0 16px;
                }
              }
            }
          }
          //maps
          .metro-map {
            height: 100%;
            width: 100%;
            background-color: $color-white;
          }
          #metromap {
            height: 100%;
            width: 100%;
            position: relative;
            > div {
              position: absolute;
              display: none;
              .points {
                > div {
                  position: absolute;
                }
              }
            }
            .metroPoint {
              position: absolute;
              width: 20px;
              height: 20px;
              background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
              z-index: 1;
            }
          }
          #metrozoom {
            z-index: 101;
            position: absolute;
            left: 30px;
            top: 50px;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .metroPoint {
              position: absolute;
              width: 20px;
              height: 20px;
              background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
              z-index: 1;
            }
          }
          .map {
            .location {
              input[name='LOCATION_GROUP'] {
                display: none;
              }
              .statuspoint {
                .status_opened {
                  background-color: $color-dark-pink;
                  border: 1px solid $color-dark-pink;
                }
              }
              .button {
                margin: 0px auto;
                width: 130px;
                .select-access-point {
                  background-color: $color-white;
                  border: 1px solid $dark-gray;
                  color: $color-black;
                  cursor: pointer;
                  height: auto;
                  margin: 10px 0 0;
                  padding: 7px 10px;
                  text-align: center;
                  text-decoration: none !important;
                  width: 140px;
                  &.disabled {
                    border: 1px solid $light-gray;
                    color: $light-gray;
                    cursor: default;
                  }
                }
                &.more-info {
                  background-color: $color-black;
                }
                &.more-info:hover {
                  color: $color-white;
                }
              }
            }
            .gm-style {
              .map-info-window {
                .location {
                  input {
                    display: none !important;
                  }
                }
              }
            }
          } //map
        } //map-wrapper

        .map-title {
          font-size: 15px;
          font-weight: bold;
          margin: 0;
          padding: 20px;
          border-color: $medium-gray;
          text-align: center;
          @include breakpoint($small-down) {
            padding: 0;
            margin: 5px 0;
          }
        }
        .locations {
          .map-title {
            display: block;
            @include breakpoint($small-down) {
              display: none;
            }
          }
        }
        &.map {
          .locations {
            .location-info-windows {
              display: block;
            }
          }
        }
        &.metro {
          .locations {
            .metro-list,
            .location-info-windows {
              display: none;
            }
          }
        }
        .locations {
          .metro-list {
            padding: 10px 20px 10px 10px;
            .metro-info {
              padding: 3px 0;
            }
            .metro-qty {
              float: right;
            }
            @include breakpoint($small-down) {
              display: none;
              .metro-qty {
                margin-top: -17px;
              }
            }
          }
          @include breakpoint($small-down) {
            .location-info-windows {
              display: block;
            }
            &.metro-on {
              .metro-list {
                display: block;
              }
              .location-info-windows {
                display: none;
              }
            }
          }
        }
        .map-window-more-info {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 202;
          background: $light-gray;
          border: 1px solid $dark-gray;
          border-radius: 10px;
          opacity: 0.92;
          .content {
            padding: 0 20px 20px;
            .button {
              display: inline-block;
              &.select-access-point {
                border: 1px solid $dark-gray;
                color: $color-black;
              }
            }
            p {
              margin-bottom: 0;
            }
            h3 {
              font-size: 14px;
            }
            button {
              overflow: hidden;
              .select-access-point {
                background-color: $color-white;
                border: 1px solid $dark-gray;
                color: $color-black;
                cursor: pointer;
                height: auto;
                margin: 10px 0 0;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none !important;
                width: 140px;
                &.disabled {
                  border: 1px solid $light-gray;
                  color: $light-gray;
                  cursor: default;
                }
              }
            }
            .hint {
              font-size: 18px;
              display: inline-block;
              padding: 4px 14px;
              margin-bottom: 10px;
              border-radius: 5px;
            }
            .hint_green {
              background: $color-dark-pink;
              color: $color-white;
            }
            .hint_red {
              background: $color-red;
              color: $color-white;
            }
          }
          .inner {
            position: relative;
            overflow: hidden;
            .close {
              position: absolute;
              top: 0;
              right: 0;
              padding: 10px;
              cursor: pointer;
              span {
                &:before {
                  content: 'X';
                }
              }
            }
          } //inner
          @include breakpoint($small-down) {
            background-color: $color-white;
          }
        }
        .submit {
          bottom: 1px;
          left: 50%;
          margin-top: 20px;
          position: absolute;
          width: auto;
          z-index: 1;
          button {
            display: none;
          }
        }
      } //map-container
      .customzoom {
        .minus {
          position: absolute;
          width: 24px;
          height: 23px;
          background: url(//pickpoint.ru/select/design/zoomminus.gif) center center no-repeat;
          cursor: pointer;
          top: 115px;
        }
        .plus {
          position: absolute;
          width: 24px;
          height: 22px;
          background: url(//pickpoint.ru/select/design/zoomplus.gif) center center no-repeat;
          cursor: pointer;
        }
        .drag {
          position: absolute;
          width: 10px;
          height: 22px;
          background: url(//pickpoint.ru/select/design/zoomdrag.gif) center center no-repeat;
          cursor: pointer;
          left: 7px;
          top: 25px;
          z-index: 2;
        }
        .line {
          position: absolute;
          background: $dark-gray;
          width: 1px;
          height: 75px;
          top: 35px;
          left: 12px;
          z-index: 1;
        }
      }
      .map-container {
        height: 100%;
        position: relative;
        overflow: hidden;
        .address-map-search-panel {
          position: inherit;
          z-index: 1;
          margin: 0;
          .search-box-toggle {
            height: 20px !important;
          }
          .status-bar-wrapper {
            position: fixed;
            bottom: 20px;
            left: 50%;
            width: 30em;
            margin-left: -15em;
            z-index: 2;
            .status-bar {
              background-color: $color-black;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              .message-text {
                color: $color-white;
                padding: 5px;
                font-weight: bold;
              }
            }
            @include breakpoint($small-down) {
              left: 0;
              width: auto;
              margin-left: 0;
            }
          }
          .search-box-field {
            width: 140px;
            margin-right: 60px;
            float: none;
            -webkit-appearance: none;
            border-radius: 0;
            -webkit-border-radius: 0px;
            font-size: 12px;
            @include breakpoint($small-down) {
              float: left;
              width: 74%;
            }
          }
          .search-box-submit {
            height: 63px !important;
            width: 60px;
            position: absolute;
            top: -1px;
            right: 0;
            line-height: initial;
            color: white;
            padding: 12px 20px;
            background-color: $color-black;
            span {
              display: block;
              margin-top: -2px;
            }
            i.icon--search {
              font-size: large;
              font-family: 'icons';
              line-height: 1;
              font-weight: normal;
              font-style: normal;
            }
            @include breakpoint($small-down) {
              height: auto;
              width: 22%;
              margin-right: 0;
            }
          }
          .search-box {
            overflow: hidden;
            width: initial;
            position: relative;
          }
        }
      } // map-container
    }
    .status_green {
      background-color: $color-dark-pink;
    }
    .status_red {
      background-color: $color-dark-red;
    }
    @include breakpoint($small-down) {
      .group_location_radio {
        float: left;
        width: 25px;
      }
      .group_location_list {
        margin-left: 30px;
      }
      .metro_list_radio {
        float: left;
        width: 25px;
      }
    }
  }
  #map-foreground-node {
    left: 5px;
    padding: 0;
    background-color: $light-gray;
    border: 1px solid $dark-gray;
    position: fixed;
    width: 100% !important;
    z-index: 9999;
    @include breakpoint($small-down) {
      margin-left: 0 !important;
      left: 0;
    }
  }
}
