.profile-page {
  &__content {
    .newsletter-info {
      overflow: hidden;
      &__fieldset {
        .newsletter-info__item {
          width: 50%;
          margin-bottom: 50px;
        }
      }
    }
    #profile_preferences {
      input[type='submit'] {
        padding: 7px 10px 5px 10px;
        margin-right: 25px;
        margin-bottom: 120px;
      }
    }
  }
}

.account-section {
  margin-bottom: 0px;
  &.profile-page {
    margin-bottom: 0px;
  }
  &.past-purchases {
    .past-purchases__content {
      padding-bottom: 50px;
    }
  }
  .payment-info {
    display: none;
  }
  .address-book-page {
    &__content {
      h2.address-book-page__header {
        margin-bottom: 0px;
      }
      .address-book__link {
        padding: 7px 27px 5px 27px;
      }
      .address-book {
        border-bottom: none;
        &__content {
          .address-book__item-container {
            h2.section-head {
              margin-top: 40px;
            }
            .address-book__item {
              margin-bottom: 24px;
              padding-top: 0px;
              .address-book__subheader {
                margin: 19px 0px 10px 0px;
              }
            }
          }
        }
      }
    }
  }
  .account-section__header {
    margin-bottom: 0px;
    .account-page-header {
      display: inline-block;
      margin-bottom: 15px;
    }
    .account-section__tagline {
      margin-top: 0px;
    }
  }
}

fieldset {
  &.address {
    .phone_label_container {
      clear: both;
      margin-bottom: 10px;
    }
    .city_container {
      input {
        width: 97%;
      }
    }
    .country_container {
      select {
        width: 97%;
      }
    }
    .required-message {
      margin-bottom: 10px;
    }
  }
}

.ac_list {
  border: 1px solid $medium-gray;
  overflow: hidden;
  padding: 0;
  width: 97%;
  z-index: 20;
  ul {
    height: auto;
    list-style: outside none none;
    margin: 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    width: 100%;
    li {
      border-top: 1px solid $medium-gray;
      cursor: pointer;
      display: block;
      margin: 0;
      overflow: hidden;
      padding: 2px 5px;
      position: relative;
      &:first-child {
        border-top: none;
      }
    }
    .ac_hover {
      background-color: $lookup_background_color;
    }
  }
}

.order-products {
  .cart-item__wrap {
    .cart-item {
      &__header {
        @include breakpoint($medium-landscape-only) {
          font-size: 10px;
        }
      }
    }
  }
}

.order-details-page {
  &__content {
    .order-products {
      .cart-item {
        &__products {
          width: 30%;
        }
        &__tracking {
          width: 25%;
        }
      }
    }
  }
}

#bt_notification {
  .bt-bottom-right {
    right: 0;
    bottom: 0;
    left: auto !important;
    top: auto !important;
    padding: 20px 10px 20px 20px;
    position: fixed;
    width: 420px;
    border-bottom-width: 0;
    a.site-utils__dropdown__close.bt-close {
      text-decoration: none;
      .icon.icon--close {
        font-size: 20px;
      }
    }
  }
  .bt-content {
    border: 1px solid black;
    background-color: white;
    z-index: 9999;
    font-size: 12px;
    line-height: 16px;
  }
  .bt-inner-content {
    width: 90%;
    float: left;
  }
  .bt_contentarea {
    margin-top: 10px;
  }
}

.site-wide-footer {
  .site-email-signup__fields {
    width: 50% !important;
    @include breakpoint($medium-portrait-only) {
      width: 100% !important;
    }
    .site-email-signup__field--mobile {
      display: none;
    }
    .site-email-signup__field--email,
    .site-email-signup__field--email:focus {
      width: 95%;
      @include breakpoint($medium-portrait-only) {
        width: 100%;
      }
    }
  }
}

.site-email-signup__terms-conditions {
  display: none;
  overflow: hidden;
}

.order-details-page {
  .order-products {
    .cart-item {
      &__header {
        @include breakpoint($medium-landscape-only) {
          padding-bottom: 16px;
        }
      }
      &__qty {
        @include breakpoint($medium-landscape-only) {
          width: 11%;
          letter-spacing: 0px;
        }
      }
      &__total {
        @include breakpoint($medium-landscape-only) {
          width: 13.5%;
          letter-spacing: 0px;
        }
      }
      .product-info__spp-status {
        .coming_soon_stock_msg {
          float: left;
          clear: both;
          margin-top: 15px;
        }
      }
    }
  }
}

.account-page {
  .section-content {
    .account-profile__email p {
      margin-top: 0;
    }
  }
  .account-address {
    .section-content {
      h4 {
        letter-spacing: 0;
      }
      a {
        margin-bottom: 2px;
      }
    }
  }
  .account-page__content {
    .account-page__top-section {
      .account-profile {
        padding-top: 40px;
        margin-top: 10px;
        .section-head__header {
          margin-top: 0px;
        }
      }
      .account-orders {
        padding-top: 50px;
        padding-left: 90px;
        .section-head__header {
          margin-top: 0px;
        }
      }
    }
    .account-page__bottom-section {
      .account-address {
        padding-top: 50px;
        padding-left: 78px;
        .section-head__header {
          margin-bottom: 24px;
          margin-top: 0px;
        }
        .section-content {
          h4 {
            margin-bottom: 24px;
          }
        }
      }
    }
    .past-purchases-wrapper {
      margin-bottom: 50px;
      display: inline-block;
      .past-purchases-page__content {
        width: 100%;
        .past-purchases {
          padding-top: 10px;
          .product-item {
            .product-item__add-to-cart {
              .product-item__link {
                padding: 7px 14px 5px 14px;
              }
            }
          }
        }
      }
    }
  }
}

.favorites-page {
  .favorites-page__wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    display: block;
    .product-item__img {
      .product-image__image {
        height: auto;
      }
    }
    .product-info__actions {
      .product-info__actions--reorder {
        .button {
          padding: 7px 14px 5px 14px;
        }
      }
    }
  }
}

.mpp-sort__container {
  .select-box__label {
    padding: 0 3em 0 0.5em;
  }
}

.account-section__sidebar {
  .account-welcome {
    h1.account-welcome__header {
      letter-spacing: 0;
      font-size: 22px;
      line-height: 24px;
      margin-top: 18px;
    }
  }
}

.error.cart-item__error--inactive,
.error.cart-item__error--soldout {
  clear: both;
  display: inline-block;
  margin: 10px 0 0;
  width: 100%;
}

.loyalty-early-access-overlay {
  display: none !important;
}

@media (min-width: 1024px) {
  .profile-page__content {
    .newsletter-info__item {
      label {
        span.label-content {
          padding-right: 65px;
        }
      }
    }
  }
}

.past-purchases-page {
  .product-item {
    &__add-to-cart {
      @include breakpoint($medium-landscape-only) {
        bottom: 0;
      }
    }
  }
  .past-purchases {
    padding-top: 10px;
    margin-bottom: 50px;
    display: inline-block;
    .product-item {
      .product-item__details {
        .product-item__name {
          padding-bottom: 17px;
        }
      }
      .product-item__add-to-cart {
        .product-item__link {
          padding: 7px 14px 5px 14px;
        }
      }
    }
    .account-section__tagline {
      margin-bottom: 0px;
    }
  }
}

.account-section__sidebar {
  .account-utilities {
    .sidebar-menu__item {
      @include breakpoint($medium-portrait-only) {
        line-height: 44px;
      }
      margin-bottom: 12px;
    }
    margin-top: 25px;
  }
}

@media (min-width: 1025px) {
  .order-details-page__content {
    .order-products {
      .cart-item {
        letter-spacing: 0px;
      }
      .cart-item__price {
        width: 12%;
      }
      .cart-item__total {
        width: 13%;
      }
    }
  }
}

#purchases {
  .past-purchases-page__content {
    @include breakpoint($medium-landscape-only) {
      height: auto !important;
    }
  }
}

.sign-in-page {
  .signin-block__main {
    .signin-block__title {
      margin-bottom: 38px;
    }
  }
  .signin-block__footer {
    .button {
      padding: 7px 10px 5px 10px;
    }
  }
}

.order-details-page__content {
  .order-total {
    table {
      tr {
        td {
          padding-left: 0;
        }
      }
    }
  }
}

.signin-block__form {
  .signin-block__toggle {
    margin-bottom: 25px;
  }
}
