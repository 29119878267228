.product-full {
  &__sub-row {
    color: $medium-gray;
    font-weight: 100;
    margin-top: 22px;
    height: auto;
    & ~ & {
      margin-top: 0;
    }
    &.tier1 {
      margin-bottom: 0;
    }
    &.active {
      color: $black;
      font-weight: 700;
    }
    .product-price {
      &__description--text {
        font-family: $BebasNeue_cyrillic-font;
        font-weight: 400;
        text-transform: uppercase;
        margin-#{$ldirection}: -25px;
        font-size: 18px;
      }
    }
    .product-sku-price {
      &[data-is-discountable='0'] {
        color: $black;
      }
      &__amount {
        font-size: 17px;
      }
      &__currency {
        font-family: $font--helvetica-neue;
        font-size: 22px;
        font-weight: 400;
      }
      &__trow {
        display: block;
        width: 100%;
      }
      &__tcol {
        display: inline-block;
        &.amount-loyalty {
          width: 25%;
        }
        &.description-loyalty {
          width: 65%;
          text-align: left;
        }
        .product-sku-price {
          &__currency {
            font-size: 17px;
            padding-#{$ldirection}: 5px;
          }
        }
      }
      &__value {
        &[data-is-discountable='0'] {
          color: $black;
        }
        .currency {
          font-family: $font--helvetica-neue;
          font-weight: 400;
        }
      }
    }
  }
  &__loyalty_title {
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 22px;
    font-family: $BebasNeue_cyrillic-font;
    text-transform: uppercase;
  }
  &__price {
    padding-top: 0;
    &-loyalty {
      padding: 0;
      width: 400px;
    }
  }
  .product-size-loyalty {
    padding: 0;
    margin-bottom: 18px;
  }
  &__details {
    .product-shade-picker {
      margin-top: 15px;
    }
  }
}

.loyalty-account {
  &__container {
    display: flex;
    padding: 0 10%;
    flex-direction: column;
  }
  &__row {
    display: flex;
    justify-content: space-around;
  }
  &__cell {
    margin: 10px;
    display: flex;
    width: 35%;
    align-items: center;
  }
  &__header {
    text-align: center;
  }
  &__subheader {
    padding: 10px;
    font-family: $BebasNeue_bold-font;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 18px;
    margin: 0;
    padding-left: 10px;
  }
  &__img {
    width: 75px;
    height: 75px;
  }
}

.signin-block__authed__content {
  .loyalty_link {
    margin-top: 20px;
    a {
      color: $red;
    }
  }
}

.spp {
  &__container {
    .product-full__sub-row {
      margin-left: 0;
    }
  }
}

@include breakpoint($small-only) {
  .loyalty-account {
    &__row {
      flex-direction: column;
    }
    &__img {
      width: 65px;
      height: 65px;
    }
    &__cell {
      width: 100%;
      justify-content: space-evenly;
    }
    &__subheader {
      width: 9em;
      padding: 0;
    }
  }
}

.back-link-wrapper {
  position: relative;
  .back-link {
    position: absolute;
    top: 6px;
    left: 10px;
    font-size: 18px;
    font-weight: 700;
  }
}

.esearch-product {
  &__price {
    .currency {
      font-family: $font--helvetica-neue;
    }
  }
}

.cart-block {
  &__footer {
    &__subtotal {
      &__value {
        .currency {
          font-family: $font--helvetica-neue;
        }
      }
    }
  }
}

.product-brief {
  &__content & {
    &__price {
      .currency {
        font-family: $font--helvetica-neue;
      }
    }
  }
}

.spp-add-bag-bar {
  .product-sku-price {
    &__currency {
      font-family: $font--helvetica-neue;
      font-size: 17px;
    }
    .product-sku-price {
      &__amount {
        margin-right: 5px;
      }
    }
  }
}

.favorites-page {
  &__content {
    .product-info {
      &__wrapper {
        .product-info {
          &__name {
            width: 100%;
          }
          &__price {
            width: 100%;
            text-align: left;
            .currency {
              font-family: $font--helvetica-neue;
            }
          }
        }
      }
    }
  }
}

.product-quickshop {
  &__product {
    .product-full {
      &__loyalty_product-size {
        .product-size {
          padding: 21px 0 0;
        }
      }
    }
    .product-quickshop {
      &__sub-row {
        .product-full {
          &__price {
            width: 80%;
            .product-sku-price {
              &__trow {
                margin-bottom: 5px;
              }
              &__tcol {
                &.amount-loyalty {
                  text-align: left;
                }
                &.description-loyalty {
                  .product-price {
                    &__description--text {
                      margin-#{$ldirection}: auto;
                    }
                  }
                  text-align: left;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart-items {
  &__item--price {
    .currency {
      font-family: $font--helvetica-neue;
    }
  }
  &__item--total {
    .currency {
      font-family: $font--helvetica-neue;
    }
  }
}

.cart-item {
  &__cart-details & {
    &__price {
      font-family: $font--helvetica-neue;
    }
  }
}

.past-purchases {
  &__list {
    .past-purchases-data-item {
      .past-purchases {
        &__price {
          .currency {
            font-family: $font--helvetica-neue;
          }
        }
      }
    }
  }
  &__item--details {
    .products {
      .product-info {
        &__price {
          .currency {
            font-family: $font--helvetica-neue;
          }
        }
      }
    }
  }
}

.order-details-page {
  &__content {
    .order-products {
      .cart-item {
        &__price {
          .product-price--pro-discount {
            .currency {
              font-family: $font--helvetica-neue;
            }
          }
          .product-price {
            .currency {
              font-family: $font--helvetica-neue;
            }
          }
        }
        .cart-item {
          &__total,
          &__price {
            .currency {
              font-family: $font--helvetica-neue;
            }
          }
        }
      }
    }
    .order-total {
      table {
        tbody {
          tr {
            td {
              .currency {
                font-family: $font--helvetica-neue;
              }
            }
          }
        }
      }
    }
  }
}

.checkout-panel {
  .order-summary-content {
    &__value {
      .currency {
        font-family: $font--helvetica-neue;
        font-weight: 400;
      }
    }
  }
}
