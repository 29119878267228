.product-quickshop__detail {
  .select-box {
    &.select--wide {
      max-width: 100%;
      .select-box__label {
        max-width: 100%;
        &:after {
          background-color: $light-gray;
        }
      }
    }
  }
}
