.cs-page {
  @include breakpoint($medium-portrait-only) {
    padding: 20px 15px;
  }
}

@media (min-width: 1280px) {
  .section-lighting-story {
    .hero-text--large {
      font-size: 55px;
      letter-spacing: 0px;
    }
  }
}

.bc.brand-id-Smashbox {
  color: $color-black !important;
  form#shared-_-become_customer {
    input[name='EMAIL_ADDRESS'] {
      margin-bottom: 20px;
      margin-left: 17px;
    }
    input[name='PHONE'] {
      margin-left: 17px;
      margin-bottom: 20px;
    }
  }
}

.spp-add-bag-bar__wrapper {
  max-width: 890px;
  .spp-add-bag-bar__details {
    max-width: 520px;
    .spp-add-bag-bar__price {
      .product-sku-price__value {
        font-size: 17px;
      }
    }
    .spp-add-bag-bar__size,
    .spp-add-bag-bar__shade {
      font-size: 12px;
      letter-spacing: 0;
    }
  }
}

.product__estimated-delivery {
  .product__estimated-delivery {
    &-search,
    &-submit {
      display: none;
    }
  }
}

.hero-large__inner.hero-large__inner--pc {
  .hero-large__text.hero-large__text--1 {
    @include breakpoint($medium-landscape-only) {
      font-size: 50px;
    }
  }
  .hero-text--xl {
    @include breakpoint($medium-landscape-only) {
      font-size: 95px !important;
    }
  }
  .hero-text--medium {
    @include breakpoint($medium-landscape-only) {
      font-size: 20px;
    }
  }
}

@media (min-width: 1024px) {
  .product-quickshop__actions {
    .product__inventory-status {
      line-height: 10px;
    }
  }
  footer.footer {
    .site-email-signup {
      text-align: center;
    }
    .site-email-signup__fields {
      float: none;
      display: inline-block;
      .site-email-signup__field--email {
        float: none;
      }
    }
    .site-email-signup__footer {
      float: none;
      display: inline-block;
      vertical-align: top;
      input[type='submit'] {
        padding: 8px 10px 5px 10px;
      }
    }
  }
  header.site-header {
    input[type='submit'] {
      padding: 8px 10px 5px 10px;
    }
  }
}

.bc_thanks.brand-id-Smashbox {
  p {
    color: $color-black;
  }
}

@media (min-width: 1024px) {
  .cs-page {
    padding-top: 45px;
  }
}

.page-wrapper {
  .store-locator {
    &__controls {
      .search-submit {
        padding: 10px 30px 8px;
      }
    }
  }
}

.product-quickshop {
  .product-quickshop__detail {
    .product-quickshop__name.product-full__name {
      padding-bottom: 3px;
    }
  }
}

.search-wrapper {
  .results__header {
    .dimensions__results {
      .dimension__header.results-header {
        margin-top: 2em;
      }
    }
  }
}

.section-customer-service-contact-us {
  .cs-contact-form__section {
    .cs-contact-form__categories {
      li {
        padding: 10px 18px 8px 18px;
      }
    }
  }
}

.spp__container {
  .product-full {
    .product-full__breadcrumb {
      min-height: 76px;
    }
    .product-full__details {
      margin-top: 0px;
      .product-full__sub-row {
        #BVRRSummaryContainer {
          top: 9px;
        }
      }
    }
    &__product-social {
      .product-social {
        .icon--vk {
          padding-right: 10px;
        }
      }
    }
  }
}

input[type='submit'],
input[type='button'] {
  padding: 8px 10px 5px 10px;
}

.offers-page {
  .smashcash-marketing {
    &__banner {
      &--image {
        display: none;
      }
    }
  }
}

.section-email-promotions {
  .pc_terms_conditions {
    margin: 20px 0 0 0;
    .label-content {
      float: left;
      margin: -14px 0px 0px 25px !important;
    }
  }
  #providing-email {
    margin-left: 25px;
  }
  .pc_email_promo_container {
    padding-bottom: 15px !important;
    .pc_email_privacy_popup {
      position: absolute;
      width: 50%;
    }
    width: 60.26em !important;
  }
}

.offer-code__content {
  width: 650px;
  .offer-code__description {
    font-size: 20px;
  }
  .offer-code__button {
    float: right;
  }
  .btn_apply_cc_payment,
  .btn_apply_cod_payment {
    text-decoration: none;
    background-color: #000;
    color: #fff;
    padding: 10px;
  }
}

.checkout {
  .messages {
    #error_offer_no_benefits {
      display: none;
    }
  }
}

.change-password {
  .password-field {
    &__info {
      @include breakpoint($medium-up) {
        width: 250px;
      }
    }
  }
}

.site-content {
  .basic-carousel-formatter {
    &.carousel-formatter {
      &--full-width {
        .slick-list {
          overflow: hidden;
        }
      }
      .carousel-dots {
        margin: 0;
      }
    }
  }
}

.homepage-wrapper {
  &--desktop {
    .basic-formatter {
      &__item:first-child {
        margin: 0;
      }
    }
  }
}

body {
  .lt-label {
    &.lt-label-event {
      &.lt-online {
        z-index: 999;
      }
    }
  }
}

.primer-finder-results {
  &__recommended-products {
    .product-brief {
      &__name {
        width: 100%;
      }
      &__price {
        position: relative !important;
      }
    }
  }
}
