$lookup_background_color: #d8d8d8;

.signin-block {
  &__forms {
    overflow: hidden;
  }
  &--register {
    max-width: 485px;
    .signin-block__main {
      width: 100%;
    }
  }
}

.select_error {
  color: $color-red;
}

body.checkout {
  padding: 0;
  max-width: none;
}

.page-wrapper {
  #completed_checkout_registration {
    @include breakpoint($medium-up) {
      .section-email-promotions {
        width: 100%;
      }
    }
  }
  .mpp-container {
    @include breakpoint($landscape-up) {
      .mpp-container__header {
        margin-top: 61px;
      }
    }
  }
}
/* Power Review */

.spp__container {
  #pr-reviewdisplay {
    .pr-review-display {
      .pr-rd-side-content-block {
        &.pr-rd-right {
          width: 276px;
        }
      }
    }
    .pr-helpful {
      &-yes {
        .pr-helpful-count {
          &:before {
            content: 'Да ';
            visibility: visible;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            content: 'Нет ';
            visibility: visible;
          }
        }
      }
    }
  }
  .spp_customer_reviews {
    .spp_reviews_button {
      a.review_link {
        margin-right: 136px;
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .form-group {
      &.pr-media_image-form-group,
      &.pr-media_videourl-form-group {
        label {
          width: 35% !important;
        }
      }
    }
    #pr-bottomline {
      .pr-helper-text {
        display: none;
      }
    }
  }
}

.spp__container {
  .product-full__details {
    .product-full__estimated-delivery {
      display: none;
    }
    .shipping-cost-widget {
      padding-top: 5px;
      margin-bottom: -5px;
      .shipping-cost {
        p:nth-of-type(2) {
          span:nth-child(1) {
            width: 140%;
            float: left;
          }
        }
      }
      .delivery_region {
        margin: -7px 0 5px 0;
      }
      .shipping-price {
        margin: 0 5px 5px 0;
      }
      .shipping-title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .country-container {
        display: block;
        margin-bottom: 5px;
      }
    }
    a.shipping_calc {
      display: block;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 13px;
      letter-spacing: 1px;
      padding-bottom: 10px;
      margin: 1em 0 10px 0;
    }
  }
}

#ship-calc-overlay {
  .country-container {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    input,
    select {
      width: 100%;
      float: left;
      text-transform: uppercase;
    }
  }
  .calculation-result {
    .shipping-type {
      font-weight: bold;
    }
  }
  .close-popup {
    background: $color-black;
    border: 2px solid $color-black;
    color: $color-white;
    display: inline-block;
    font-family: 'Bebas Neue Bold';
    font-size: 18px;
    font-weight: 100;
    padding: 10px 10px 7px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    width: auto;
    overflow: visible;
    border-radius: 0;
    cursor: pointer;
    letter-spacing: normal;
    float: right;
    &:hover {
      background: $color-white;
      border-color: $color-black;
      color: $color-black;
      text-decoration: none;
    }
  }
  .missing_region,
  .missing_city {
    color: $color-red !important;
  }
}

.shipping-cost-widget {
  .delivery_region {
    margin: -7px 0 5px 0;
  }
  .shipping-price {
    margin: 0 5px 5px 0;
  }
  .shipping-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .country-container {
    display: block;
    margin-bottom: 5px;
  }
}

#shipping_calculator {
  .calculation-result {
    clear: both;
  }
}

@media (min-width: 1024px) {
  .product-full__size--shaded {
    position: inherit;
  }
}

.product-quickshop__content {
  .product-quickshop__product-social {
    margin-right: 20px;
  }
}
