#signin {
  .sign-in-page {
    .signin-block {
      @include breakpoint($large-up) {
        position: relative;
        max-width: 75%;
        &__form {
          width: 44%;
          float: left;
        }
        .social-login {
          width: 50%;
          padding: 0;
          margin: 95px 0 0 25px;
          float: left;
          position: relative;
          &__container {
            padding: 20px 0 0 65px;
          }
          &__divider {
            position: absolute;
            width: auto;
            height: 100%;
            left: 21px;
            padding: 0;
            margin: 0;
            &:before {
              width: 1px;
              height: 100%;
              top: 0;
            }
            .inner {
              position: absolute;
              left: -14px;
              top: 80px;
              padding: 10px 0;
            }
          }
        }
      }
      @include breakpoint($xlarge-up) {
        max-width: 58%;
      }
    }
  }
}

.social-login {
  &__terms {
    margin-left: 23px;
  }
  &__button {
    background-color: $light-blue;
    color: $white;
    height: 50px;
    letter-spacing: 0.25px;
    line-height: 47px;
    cursor: pointer;
    min-width: 260px;
    width: 70%;
    margin: 0 auto;
    .fb-image {
      width: 24px;
      margin-right: 8px;
      vertical-align: middle;
    }
    .fb-content {
      font-family: $font--helvetica;
      font-size: 16px;
    }
  }
  &__opt-in-checkbox {
    float: left;
  }
  &__opt-in-label {
    margin-left: 23px;
    display: block;
  }
}

.word-wrap {
  white-space: nowrap;
}
/* Account profile page */
.account-profile {
  &__connected-account {
    width: 25px;
    border-radius: 3px;
  }
}

.profile-page {
  &__content {
    #profile_preferences {
      .newsletter-info {
        input[type='submit'] {
          margin-bottom: 0;
        }
      }
      .account-section {
        &__social-connect {
          float: left;
          width: 100%;
          margin: 30px 0 80px;
          #facebook_disconnect {
            float: left;
            input.form-submit {
              height: 30px;
              color: $black;
              background: $white;
            }
          }
          .social-info {
            &__content {
              p {
                margin-bottom: 0;
              }
            }
            &__connect {
              margin: 15px 0 10px;
              float: left;
              width: 100%;
              .fb-login-button {
                line-height: 24px;
              }
              .connect-facebook-button {
                display: none;
                height: 30px;
                line-height: 13px;
              }
            }
            .social-account {
              float: left;
              margin: 0 10px 10px 0;
              line-height: 27px;
              @include breakpoint($medium-up) {
                margin: 0 30px 10px 0;
              }
            }
            &__image {
              width: 25px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}
/* Checkout page */
.checkout {
  &__confirm-container {
    .checkout__content {
      padding: 0;
      margin-bottom: 30px;
    }
    .social-login {
      width: 73.5%;
      margin: 0 auto;
      @include breakpoint($landscape-up) {
        width: 50%;
      }
      &__container {
        padding: 0;
      }
      &__title {
        display: block;
        font-size: 30px;
        @include breakpoint($medium-up) {
          font-size: 36px;
          display: block;
        }
      }
      &__info,
      &__terms {
        display: block;
        text-align: center;
      }
      &__terms {
        display: none;
      }
      &__divider {
        display: none;
      }
      &__button {
        width: 270px;
        margin: 20px auto 0;
      }
    }
  }
  &__content {
    .social-login {
      padding: 0 20px 0;
      &__email-opt-in {
        padding-left: 0;
        .social-login__opt-in-checkbox {
          position: inherit;
          left: 0;
        }
      }
      &__opt-in-label {
        &:before {
          margin-right: 10px;
        }
      }
      &__button {
        width: 280px;
      }
    }
  }
  .checkout-panel {
    .social-login {
      input[type='checkbox']:checked ~ label:before {
        display: none;
      }
      &__opt-in-label {
        &:before {
          display: none;
        }
      }
    }
  }
}
/* Gnav Content */
.gnav-util--account {
  .gnav-util__content--account {
    @include breakpoint($xxlarge-up) {
      overflow-y: scroll;
      height: 550px;
    }
  }
}
