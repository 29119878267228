.checkout-panel {
  &__content.top-viewcart-buttons,
  .bottom-viewcart-buttons {
    .checkout-buttons {
      &-content {
        .go-shopping,
        &__item.continue-checkout {
          width: auto;
          padding: 0 10px;
        }
      }
    }
    .viewcart-buttons-panel {
      .checkout-buttons-content__container {
        .continue-checkout {
          margin-right: 0px;
        }
      }
    }
  }
}

.viewcart {
  .checkout-panel {
    &--recommended-products {
      .product-brief__actions {
        input[type='submit'] {
          width: auto;
        }
      }
    }
  }
}

.checkout {
  .tab-switcher {
    margin: 15px;
    .tab-bar {
      li.tab-header {
        &-samples {
          width: 100%;
        }
        &-favourties {
          width: 35%;
          a.tab {
            margin-left: 9px;
          }
          display: none;
        }
        &-pastpurchase {
          width: 37%;
          a.tab {
            @include breakpoint($landscape-up) {
              padding: 0.25em 0px;
            }
            margin-left: 9px;
          }
          display: none;
        }
      }
    }
  }
  &--confirmation-page {
    .checkout__header {
      .checkout__heading {
        text-align: center;
      }
    }
    .checkout-panel {
      &--email-and-sms-promotions {
        display: block;
        .section-sms-promotions {
          .sms-mobile {
            padding-top: 10px;
          }
          .sms-mobile-phone {
            overflow: visible;
          }
        }
      }
    }
  }
  .checkout__sidebar {
    .checkout-panel--need-help {
      display: none !important;
    }
    .checkout-panel--links {
      display: none !important;
    }
    .tab-switcher {
      max-height: initial !important;
      overflow: hidden !important;
      overflow-x: hidden !important;
    }
    .manual_offer_section {
      padding-bottom: 15px !important;
      .checkout-panel__header {
        padding-top: 5px;
      }
      .offer-code-content {
        #offer_code {
          .offer-code-content__input {
            input[type='text'] {
              width: 45%;
              @include breakpoint($medium-landscape-only) {
                width: 55%;
              }
            }
          }
        }
      }
    }
    .checkout-panel__content {
      ul.product-list {
        overflow: hidden;
      }
    }
  }
}

.page-wrapper {
  .checkout-single-page {
    .checkout-panel {
      &--shipping {
        .checkout-panel {
          &__content.select-address {
            .form-item.select-menu {
              margin-bottom: 20px;
              select {
                width: 785px;
              }
            }
            .address-form {
              .city_container {
                width: 100%;
              }
              .form-item.country-id {
                margin: 0;
              }
              .form-item.address1,
              .form-item.address3,
              .phone-2 {
                width: 48%;
                float: left;
              }
              .form-item.address2,
              .form-item.address4,
              .postal_code_container {
                width: 48%;
                float: right;
              }
            }
          }
        }
        .delivery-service-point {
          margin: 10px 0;
          .row label {
            display: block;
          }
        }
        .shipping-address-display {
          clear: both;
        }
        input[type='radio'] {
          width: auto;
        }
        .pick-loctions-container {
          margin-top: 10px;
          width: 100%;
          .form-item {
            display: block;
            float: left;
            padding-right: 10px;
            width: 32%;
            label {
              float: left;
            }
            select {
              width: 100%;
            }
          }
          .select-box__label {
            width: 238px;
          }
        }
        .local-collection {
          .set-collection-point {
            .button {
              cursor: pointer;
            }
          }
        }
        .delivery_address_section {
          .select-menu {
            .select-box {
              width: 100%;
              &__label {
                width: 100%;
              }
            }
          }
        }
        #shipmethod-panel {
          border: none;
          margin: 0 15px;
          .ship-method-group {
            .form-item {
              label {
                display: none;
              }
            }
            &-label {
              .radio-wrapper {
                margin-right: 0px;
                display: inline-block;
                width: 49%;
                vertical-align: top;
                .opt_desc {
                  margin: 10px 0 0 23px;
                  display: block;
                }
              }
            }
          }
        }
        #pickpoint_customer_info {
          .show-collection-point {
            h3 {
              display: inline-block;
            }
          }
        }
      }
      #delivery-options-group {
        .radio-wrapper {
          margin-right: 35px;
        }
      }
      &--sign-in-paypal {
        display: block;
      }
      &--payment {
        .payment-type {
          label:first-child {
            display: none;
          }
        }
      }
    }
  }
}

#confirm {
  .registration-content {
    .checkout-registration {
      &__password {
        .password {
          input[type='password'],
          input[type='text'] {
            width: 60% !important;
          }
        }
      }
    }
  }
}

.gnav-util__content {
  .cart-block__items {
    padding: 0 12px;
    .cart-item__img {
      width: 26%;
      margin-right: 1%;
    }
    .cart-item__info {
      width: 32%;
      margin-right: 1%;
    }
    .cart-item__cart-details {
      width: 40%;
      .cart-item__qty {
        letter-spacing: 0;
      }
      .cart-item__price {
        letter-spacing: 0;
      }
    }
  }
}

.click_and_collect_map {
  .overlay-content {
    .map-container {
      .loading {
        opacity: 1;
        text-align: left;
        &:before {
          display: inline;
        }
      }
    }
  }
}

.order-details-page__content {
  .order-products {
    .cart-item__tracking {
      letter-spacing: 0.6px;
    }
  }
}

.checkout__confirm-container {
  .confirmation-content {
    &__confirm-text--not-signed-in {
      a {
        pointer-events: none !important;
        text-decoration: inherit !important;
      }
    }
  }
}

.checkout.viewcart {
  .checkout__content {
    .checkout-panel--bottom-section {
      .total-container {
        .total.value {
          width: 29%;
          @include breakpoint($medium-portrait-only) {
            width: 200px;
          }
          text-align: right;
        }
        .total.label {
          width: 68% !important;
        }
      }
    }
    .checkout__header {
      padding-bottom: 0px;
      .checkout__heading {
        padding-bottom: 33px;
      }
    }
    .checkout-panel--viewcart {
      .cart-items__header {
        border-bottom: 1px solid $dark-gray;
        &--item {
          border-bottom: none;
          padding: 7px 0px 15px 0px;
        }
      }
      .messages {
        margin: 13px;
      }
    }
  }
}

.checkout-single-page {
  .checkout-panel--shipping {
    .checkout-panel__content.shipping-address-display-content {
      #delivery-address-h {
        h3.checkout-panel__heading {
          padding-bottom: 5px;
          display: inline-block;
        }
      }
      p {
        margin-bottom: 0px;
      }
    }
  }
}

.viewcart {
  #order-summary-panel {
    .checkout-panel--viewcart-buttons {
      padding: 10px 0px 0 20px;
    }
  }
  .checkout-panel--order-summary {
    .order-summary-content__value {
      text-align: right;
    }
    .order-summary-content__value--total::first-letter {
      vertical-align: top;
    }
  }
}

.checkout-footer__customer-service {
  width: 100%;
  float: none;
}

.checkout.viewcart {
  .checkout-panel--viewcart {
    .cart-items__item {
      padding-bottom: 0px;
      .cart-items__item--desc {
        .cart--item__product-name {
          padding-right: 30px;
        }
        .cart--item__color {
          padding-right: 20px;
        }
        .cart--item__remove-form {
          margin-bottom: 0px;
          padding-bottom: 52px;
          margin-right: 10px;
          a.remove_link {
            border-bottom: 1px solid black;
            padding-bottom: 1px;
            text-decoration: none !important;
          }
        }
        .cart--item__add-to-favorites {
          border-bottom: 1px solid black;
          padding-bottom: 1px;
          text-decoration: none !important;
        }
        .cart--item__size {
          padding-right: 20px;
          letter-spacing: 0px;
        }
      }
    }
    .cart-items__item--total.free-sample-item {
      text-transform: none;
    }
    .cart-items__item--total.free-sample-item::first-letter {
      font-size: 13px;
    }
  }
}

.checkout.viewcart {
  .checkout-panel--viewcart,
  .panel--viewcart {
    .cart-items__item--price {
      @include breakpoint($medium-portrait-only) {
        float: left;
      }
    }
    .cart-items__item--qty {
      @include breakpoint($medium-portrait-only) {
        padding-left: 7% !important;
      }
    }
    .total {
      @include breakpoint($medium-portrait-only) {
        bottom: 43px;
      }
    }
    .cart-items {
      @include breakpoint($medium-portrait-only) {
        border-top: none;
      }
      &__item {
        &--total {
          &.free-sample-item {
            &::first-letter {
              vertical-align: baseline;
            }
          }
        }
      }
    }
  }
  .checkout__header {
    .checkout__heading {
      @include breakpoint($medium-portrait-only) {
        text-align: center;
      }
    }
  }
  .checkout-panel--order-summary {
    .order-summary-content__value {
      @include breakpoint($medium-portrait-only) {
        width: 150px;
      }
    }
    .total-container {
      @include breakpoint($medium-portrait-only) {
        float: none;
      }
    }
  }
}

.page-wrapper {
  .checkout-single-page {
    .checkout-panel--shipping,
    .checkout-panel--payment {
      .checkout-panel__content.select-address {
        .address-form {
          .form-item.first-name,
          .form-item.phone-1 {
            width: 50%;
            padding-right: 6.5px;
            label {
              margin-bottom: 15px;
            }
          }
          .form-item.last-name {
            width: 50%;
            padding-left: 6.5px;
            label {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .checkout-panel--shipping {
      .checkout-panel__content.select-address {
        .address-form {
          .form-item.address2,
          .form-item.address4,
          .form-item.postal_code_container {
            width: 50%;
            padding-left: 6.5px;
            label {
              margin-bottom: 15px;
            }
          }
          .form-item.address1,
          .form-item.address3,
          .form-item.phone-2 {
            width: 50%;
            padding-right: 6.5px;
            label {
              margin-bottom: 15px;
            }
          }
          .form-item.city_container {
            label {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.checkout.shipping {
  .checkout__sidebar {
    .no-replenishment {
      padding-top: 20px;
      .checkout-panel__header {
        .checkout-panel__heading {
          padding-bottom: 21px;
        }
      }
    }
    #order-summary-panel {
      .checkout-panel--order-summary {
        .checkout-panel__heading {
          padding: 21px 0px;
        }
      }
    }
    .manual_offer_section {
      .checkout-panel__header {
        .checkout-panel__heading {
          padding: 21px 0px;
          clear: both;
        }
      }
    }
    .checkout-panel--links {
      .checkout-panel__heading {
        padding: 21px 0px;
      }
    }
    .checkout-panel--need-help {
      .checkout-panel__heading {
        padding-bottom: 21px;
      }
      .need-help-content {
        &__heading {
          margin: 21px 0px 17px 0px;
        }
      }
    }
  }
  .checkout-panel--shipping {
    .checkout-panel__content.shipping-address-display-content {
      margin: 0px;
      #delivery-address-h {
        .shipping-address-display-content__heading {
          margin-top: 35px;
        }
      }
    }
    div#shipping-address-display {
      margin-bottom: 33px;
      #delivery-address-h {
        .checkout__subtitle {
          margin: 33px 0px 21px 0px;
        }
      }
    }
  }
}

.checkout-single-page {
  section.checkout-panel.collapsed {
    .review-panel__title {
      border-top: none;
    }
  }
}

body#index.checkout {
  display: block !important;
}

#viewcart {
  .checkout__content {
    .cart-items__item--desc {
      @include breakpoint($medium-portrait-only) {
        margin-bottom: 25px;
      }
    }
  }
}

.page-wrapper {
  .checkout-single-page {
    .checkout-panel--shipping {
      .pick-loctions-container {
        .form-item {
          @include breakpoint($medium-portrait-only) {
            width: 40%;
          }
          @include breakpoint($medium-landscape-only) {
            width: 45%;
          }
        }
      }
    }
  }
}

#map-foreground-node.click_and_collect_map {
  margin-left: 0px !important;
}

.checkout-footer {
  .checkout-footer__cta-wrapper.pc-hidden {
    margin-bottom: 0px;
    .footer__cta-btn {
      margin-bottom: 10px;
      float: left;
    }
    .menu_item--lvl-1 {
      display: block;
      text-align: left;
      .menu_link--lvl-1 {
        display: block;
        padding: 10px 12px;
        text-align: left;
        font-size: 16px;
      }
    }
    .stores,
    .customer-service {
      clear: both;
      font-family: $BebasNeue_bold-font;
      text-transform: uppercase;
    }
  }
}

.site-wide-footer {
  .pc-hidden.footer__cta-wrapper {
    .footer__cta-btn {
      float: left;
    }
  }
}

.checkout.viewcart,
.checkout.shipping {
  .bottom-section-content {
    .order-summary-content__value {
      padding-left: 50px;
    }
  }
}

.click_and_collect_map {
  .overlay-content {
    .map-container {
      .map-wrapper {
        .maps {
          .metro-map {
            .popup_container {
              min-height: 300px;
              min-width: 300px;
              #content-popup-metromap {
                height: 300px;
              }
              .itemspopuplist_two#content-popup-metromap {
                width: 480px;
                height: 320px;
              }
              .itemspopuplist_one {
                .location_wrapper {
                  width: 260px;
                }
              }
              .location_wrapper {
                height: 300px;
                width: 230px;
                a.button {
                  background: $color-black;
                  border: 2px solid $color-black;
                  padding: 10px;
                  position: absolute;
                  bottom: 65px;
                  width: 90%;
                }
                div.button {
                  padding: 15px 0px 0px 0px;
                  width: 90%;
                }
                a.select-access-point {
                  width: 90%;
                  bottom: 10px;
                  left: 0px;
                }
                a.more-info {
                  background-color: $color-white;
                  border: 1px solid #6e6e6e;
                  color: #000000;
                }
              }
            }
          }
        }
      }
    }
  }
}

.checkout.shipping {
  .checkout-panel--shipping {
    div#shipping-address-display {
      word-wrap: break-word;
    }
  }
}

.viewcart {
  .checkout-panel {
    .bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .checkout-buttons-content {
          a.continue-shopping {
            top: 35%;
          }
          .checkout-buttons-content__container {
            .continue-checkout {
              height: 33px;
              padding: 7px 10px 7px 10px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}

.checkout__content {
  section.checkout-panel.panel {
    margin-bottom: 0px;
  }
}

.delivery-service-point {
  span.label-content {
    cursor: default;
  }
}

#form--checkout_shipmethod--field--DELIVERY_SERVICE_POINT_ID {
  .js-select-box-label {
    border: 0;
    cursor: default;
    padding: 0 4em 0 0.5em;
  }
  .select-box__label:after {
    display: none;
  }
  .select-box__options.open {
    display: none;
  }
}
