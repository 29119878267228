.checkout {
  .form-container {
    .form-item {
      &.phone-2 {
        margin-left: 0;
        label:after {
          content: '';
        }
      }
      &--full {
        input[type='text'] {
          width: 100%;
        }
      }
    }
  }
  .corporate-invoice-container {
    .corporate-options {
      margin-top: 1em;
      .select-box__label {
        margin-bottom: 0.5em;
      }
      .btn {
        width: auto;
        margin-bottom: 0.6em;
      }
    }
  }
}

.checkout-single-page {
  .checkout-panel--shipping {
    .checkout-panel__content {
      .corporate-invoice-display-content {
        margin: 10px 0;
        word-wrap: break-word;
        @include breakpoint($max-width) {
          width: 47%;
          margin: 20px 0;
          padding: 0;
        }
        @include breakpoint(max-width $max-width) {
          float: none;
        }
        .checkout-panel__heading {
          text-indent: 0;
          border-bottom: 1px solid $color-border;
          margin-bottom: 10px;
        }
      }
    }
  }
  .checkout-panel--payment {
    .payment-description {
      display: none;
    }
  }
}

.pac-container {
  z-index: 9999 !important;
}

.account-section__content,
.address-form,
.payment-form {
  input[type='checkbox'] ~ label.label_error {
    color: $red;
  }
}

.site-header__main {
  .menu__item--signed-in {
    display: block;
  }
}

.change-password {
  #appt-book-my-appointments-no-signin {
    .password-field {
      &__info {
        @include breakpoint($medium-up) {
          #{$ldirection}: 13%;
          top: 30%;
        }
      }
    }
  }
}
