.font--knockout29 {
  font-family: $BebasNeue_cyrillic-font;
}

.font--knockout47 {
  font-family: $BebasNeue_cyrillic-font;
}

.font--knockout49 {
  font-family: $BebasNeue_bold-font;
}
