.profile-page {
  &__content {
    .optional-info {
      display: block;
      &__fieldset {
        width: 80%;
      }
      &__item {
        margin: 0.8em 0;
      }
      .select-box {
        margin-bottom: 0.5em;
      }
      .birth_day_container,
      .birth_month_container,
      .birth_year_container {
        width: 42.6%;
        float: left;
        margin: 3px 19px 2px 0;
        .select-box__options {
          height: 185px;
        }
      }
    }
    .newsletter-info {
      overflow: auto;
      &__fieldset {
        width: 100%;
      }
    }
    .sms {
      &-info__item {
        span.label-content {
          color: $color-white;
        }
      }
    }
  }
  #account_title {
    margin: 10px 0;
    span.label-content {
      display: block;
    }
  }
  form {
    &#profile_preferences {
      overflow: auto;
    }
  }
}

.optional-info,
.country_container,
.payment-form {
  .select-box {
    min-width: initial;
    width: 100%;
    &__label,
    &__options {
      min-width: 100%;
    }
  }
}

.address-form {
  .title_container {
    select {
      width: 47%;
    }
  }
  .delivery-instruction {
    textarea {
      width: 100%;
    }
  }
}

.order-details-page {
  &__content {
    .order-total {
      table {
        tr th {
          padding-left: 0;
          width: 65%;
        }
      }
    }
  }
}
